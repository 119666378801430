import { useApi } from "providers/ProvideApi";
import useGet from "./useGet";

export const useGetRates = () => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/rates?page=1&itemsPerPage=500`,
    []
  );
  return [data && data.items, isLoading, error];
};

export const useGetUser = () => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/user`);
  return [data && data.user, isLoading, error];
};

export const useGetTopic = (value) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/topics/${value}`);
  return [data && data.topic, isLoading, error];
};

export const useCauseError = (code) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/error/${code}`);
  return [data, isLoading, error];
};

export { default as useRequestOtp } from "./useRequestOtp";
export { default as useRequestReset } from "./useRequestReset";
export { default as useAddUser } from "./useAddUser";
export { default as useAddRate } from "./useAddRate";
export { default as useAddTopic } from "./useAddTopic";
export { default as useAddChapter } from "./useAddChapter";
export { default as useUpdateChapter } from "./useUpdateChapter";
export { default as useManageTopicRates } from "./useManageTopicRates";
export { default as useManageTopicCredentials } from "./useManageTopicCredentials";
export { default as useManageTopicWarfares } from "./useManageTopicWarfares";
export { default as useManageTopicQualifications } from "./useManageTopicQualifications";
export { default as useGetPaginatedUsers } from "./useGetPaginatedUsers";
export { default as useGetPaginatedBibs } from "./useGetPaginatedBibs";
export { default as useGetPaginatedRates } from "./useGetPaginatedRates";
export { default as useGetPaginatedTopics } from "./useGetPaginatedTopics";
export { default as useGetPaginatedChapters } from "./useGetPaginatedChapters";
export { default as useGetPaginatedQuestions } from "./useGetPaginatedQuestions";
export { default as useManageItem } from "./useManageItem";

export { default as useGetTopicChapters } from "./useGetTopicChapters";
export { default as useGetTopicQuestions } from "./useGetTopicQuestions";
export { default as useGetChapterQuestions } from "./useGetChapterQuestions";
export { default as useAddChapterQuestion } from "./useAddChapterQuestion";
export { default as useManageBibAssignment } from "./useManageBibAssignment";
export { default as useManageBatchBibAssignment } from "./useManageBatchBibAssignment";

export { default as useGetTopicStatistics } from "./useGetTopicStatistics";
export { default as useBibScraper } from "./useBibScraper";
export { default as useUploadBibText } from "./useUploadBibText";
export { default as useGetBib } from "./useGetBib";
export { default as useReconcileBibs } from "./useReconcileBibs";
export { default as useReconcileBibsAsJob } from "./useReconcileBibsAsJob";
export { default as useCheckJobStatus } from "./useCheckJobStatus";
export { default as useCopyChapterQuestions } from "./useCopyChapterQuestions";
export { default as useGetCustomer } from "./useGetCustomer";
export { default as useGetBibs } from "./useGetBibs";
