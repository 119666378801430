import { useEffect } from "react";
import useHasChanged from "hooks/useHasChanged";
import usePaginationManager from "hooks/usePaginationManager";

export const useGetPaginatedUsers = (search, admin, premium, active) => {
  const searchHasChanged = useHasChanged(search);
  const adminHasChanged = useHasChanged(admin);
  const premiumHasChanged = useHasChanged(premium);
  const activeHasChanged = useHasChanged(active);
  const { getPackage, reset, makeLoader } = usePaginationManager(
    "/api/users",
    100
  );

  useEffect(() => {
    if (
      searchHasChanged ||
      adminHasChanged ||
      premiumHasChanged ||
      activeHasChanged
    ) {
      reset();
      loadNextPage();
    }
  });

  const more = [
    {
      qname: "admin",
      value: admin,
    },
    {
      qname: "premium",
      value: premium,
    },
    {
      qname: "active",
      value: active,
    },
  ];

  const loadNextPage = makeLoader(search, more);

  return getPackage(loadNextPage);
};

export default useGetPaginatedUsers;
