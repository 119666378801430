import React from "react";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ColumnContainer } from "advancement-solutions-components/dist/components";
import NavigationCard from "./NavigationCard";
import {
  USERS,
  RATES,
  TOPICS,
  QUESTIONS,
  BIBS,
  DASHBOARD,
  BIB_TOOLS,
} from "routes";

const Cards = ({ isMobile, children }) => (
  <Box
    sx={{
      maxWidth: 700,
      margin: 1,
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: isMobile ? "center" : "left",
    }}
  >
    {children}
  </Box>
);

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ColumnContainer>
      <Cards isMobile={isMobile}>
        <NavigationCard
          title="Manage Users"
          description="Change passwords, activate, deactivate..."
          url={`${USERS}?active=true`}
        />
        <NavigationCard
          title="Manage Rates"
          description="Add, edit, activate, deactivate..."
          url={`${RATES}?active=true`}
        />
        <NavigationCard
          title="Manage Topics"
          description="Add, edit, publish..."
          url={`${TOPICS}?published=true`}
        />
        <NavigationCard
          title="Manage Questions"
          description="Add, edit, activate, deactivate..."
          url={`${QUESTIONS}?active=true`}
        />
        <NavigationCard
          title="Manage BIBS"
          description="Edit..."
          url={`${BIBS}?active=true`}
        />
        <NavigationCard
          title="Dashboard"
          description="View..."
          url={`${DASHBOARD}`}
        />
        <NavigationCard
          title="Bib Tools"
          description="Upload and manage US Navy bibs page..."
          url={`${BIB_TOOLS}`}
        />
      </Cards>
    </ColumnContainer>
  );
};

export default Navigation;
