import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";
import { useAlert } from "advancement-solutions-components/dist/providers";

export const useManageTopicQualifications = (topic) => {
  const { authApi } = useApi();
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyAdding, setIsBusyAdding] = useState(false);
  const [isBusyRemoving, setIsBusyRemoving] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { send } = useAlert();

  const add = async (rateIds) => {
    try {
      setIsBusyAdding(true);
      setError(null);
      const response = await authApi.post(
        `/api/topics/${topic.id}/qualifications`,
        rateIds
      );
      setData(response.data);
      send({
        message: "Qualification assignments activated.",
        severity: "info",
      });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Qualification assignments were not activated: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsBusyAdding(false);
    }
  };

  const remove = async (rateId) => {
    try {
      setIsBusyRemoving(true);
      setError(null);
      const response = await authApi.delete(
        `/api/topics/${topic.id}/qualifications/${rateId}`
      );
      setData(response.data);
      send({
        message: "Qualification assignments inactivated.",
        severity: "info",
      });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Qualification assignments were not inactivated: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsBusyRemoving(false);
    }
  };

  const removeAll = async () => {
    try {
      setIsBusy(true);
      setError(null);
      const response = await authApi.delete(
        `/api/topics/${topic.id}/qualifications`
      );
      setData(response.data);
      send({
        message: "Qualification assignments inactivated.",
        severity: "info",
      });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Qualification assignments were not inactivated: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    const load = async () => {
      try {
        setIsBusy(true);
        setError(null);
        const response = await authApi.get(
          `/api/topics/${topic.id}/qualifications`
        );
        setData(response.data);
      } catch (e) {
        const error = wrapError(e);
        setError(error);
      } finally {
        setIsBusy(false);
      }
    };
    load();
  }, [topic]);

  return [data && data.items, add, remove, removeAll, isBusy, error];
};

export default useManageTopicQualifications;
