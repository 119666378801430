import { useState } from "react";
import { useApi } from "providers/ProvideApi";
import { wrapError } from "../../utils";

export const useCheckJobStatus = () => {
  const { authApi } = useApi();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  const check = async (id) => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await authApi.get(`/api/bibs/reconcile/job/${id}`);
      return response.data;
    } catch (e) {
      const error = wrapError(e);
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return [check, isUpdating, error];
};

export default useCheckJobStatus;
